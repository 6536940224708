@tailwind base;
@tailwind components;
@tailwind utilities;

@layer utilities {
  /* Hide scrollbar for Chrome, Safari and Opera */
  .no-scrollbar::-webkit-scrollbar {
    display: none;
  }

  /* Hide scrollbar for IE, Edge and Firefox */
  .no-scrollbar {
    -ms-overflow-style: none;
    /* IE and Edge */
    scrollbar-width: none;
    /* Firefox */
  }
}

@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@100;200;300;400;500;600;700;800;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap');

html {
  height: -webkit-fill-avaliable;
}

@supports (-webkit-touch-callout: none) {
  .h-screen {
    height: 100vh;
    max-height: -webkit-fill-available !important;
  }

  @supports (-webkit-appearance: none) {
    .os-android .h-screen {
      min-height: calc(100vh - 56px);
    }
  }
}

* {
  box-sizing: border-box;
}

.markdown > * {
  all: revert;
}

body {
  height: 100vh;
  height: -webkit-fill-avaliable;
  display: flex;
  background: linear-gradient(180deg, #f0f1ee 0%, #e7e9e4 100%);
}

body #root {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  overflow: auto;
  min-height: -webkit-fill-available;
  height: 100%;
}

.line-clamp {
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
}

.scrollbar-hidden::-webkit-scrollbar {
  display: none;
}

.scrollbar-hidden {
  scrollbar-width: none; /* Firefox */
}
/* Scroll bar stylings */
::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

/* Track */
::-webkit-scrollbar-track {
  background: var(--lightestgrey);
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #d9d9d9;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #6b7280;
}

.img-gradient::after {
  display: block;
  position: relative;
  background-image: linear-gradient(
    to bottom,
    rgba(255, 255, 255, 0) 0,
    #fff 100%
  );
  margin-top: -4rem;
  height: 4rem;
  content: '';
}

.dialog-container {
  height: calc(100% - 6rem);
}

.dialog-content-container {
  height: calc(100% - 13rem);
}

.dialog-content-container-grade {
  max-height: calc(100vh - 14rem);
}

.welcome-wrapper {
  max-height: calc(100vh - 8rem);
}

.content-welcome {
  display: grid;
  height: calc(100% - 7rem);
  align-content: space-between;
}

.scrollable {
  overflow: auto;
  -ms-overflow-style: none;
  /* para IE y Edge */
  scrollbar-width: none;
  /* para Firefox */
}

.scrollable::-webkit-scrollbar {
  display: none;
  /* para Chrome, Safari y Opera */
}

.overflow-y::-webkit-scrollbar {
  width: 8px;
}

.overflow-y::-webkit-scrollbar-track {
  margin: 1% 0;
  box-shadow: inset 0 0 5px grey;
  border-radius: 8px;
}

.overflow-y::-webkit-scrollbar-thumb {
  background: #bdbdbd;
  border-radius: 8px;
}

.overflow-y::-webkit-scrollbar-thumb:hover {
  background: #747474;
}

.overflow-rtl::-webkit-scrollbar {
  width: 8px;
}

.overflow-rtl::-webkit-scrollbar-track {
  background: none;
  margin: 0.5rem;
}

.overflow-rtl-dark.overflow-rtl::-webkit-scrollbar-thumb {
  background-color: #787878;
  border-radius: 8px;
}

.overflow-rtl-light.overflow-rtl::-webkit-scrollbar-thumb {
  background-color: #e1e1e5;
  border-radius: 8px;
}

@media (max-width: 640px) {
  .mobile-wrapper {
    max-width: calc(100vw - 2rem);
  }
}

.text-two-lines {
  line-height: 1.2;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

*,
*:focus,
*:hover {
  outline: none;
}

/* Audio styles */
audio {
  width: 300px;
  height: 30px;
}

audio::-webkit-media-controls-panel {
  background-color: #797779;
  border-radius: 10%;
}

.linenumber {
  color: green !important;
  -webkit-user-select: none !important;
  -webkit-touch-callout: none !important;
  /* iOS Safari */
  -webkit-user-select: none !important;
  /* Safari, Chrome, Opera, Samsung */
  -khtml-user-select: none !important;
  /* Konqueror HTML */
  -moz-user-select: none !important;
  /* Firefox */
  -ms-user-select: none !important;
  /* Edge, IE */
  user-select: none !important;
  /* Modern browsers */
  /* Optional: styling for line numbers */
}

.PrivateSwipeArea-root {
  width: 0 !important;
}

.message-hover:hover {
  background-color: rgba(184, 184, 184, 0.137);
  border-radius: 5px;
}

.highlight {
  background-color: rgba(255, 165, 0, 0.2);
  border-radius: 8px;
}

.login-banner-wrapper span {
  display: block !important;
}

@keyframes fadeOutUp {
  0% {
    opacity: 1;
    transform: translateY(0);
  }

  100% {
    opacity: 0;
    transform: translateY(-20px);
  }
}

.toast-exit {
  animation: fadeOutUp 0.2s forwards;
}

.textarea-content {
  position: relative;
}

.move-up-and-fade {
  position: relative;
  opacity: 1;
  transition: all 2s ease-in-out;
  animation: moveUpFadeOut 2s forwards;
}

@keyframes moveUpFadeOut {
  0% {
    transform: translateY(0);
    opacity: 1;
  }

  100% {
    transform: translateY(-30px);
    opacity: 0;
  }
}

.border-purple {
  border: 1.8px solid #464bba;
}

.border-purple-dark {
  border: 1.8px solid #9197fc;
}

.border-neon-custom {
  border-color: #f2a474;
  box-shadow: 0 0 5px #f2a474, 0 0 15px rgba(252, 228, 214, 1);
}

.border-neon-dark-custom {
  border-color: #f2a474;
  box-shadow: 0 0 5px #7a6153, 0 0 15px rgba(122, 97, 83, 1);
}

.neon-effect-custom {
  transition: box-shadow 0.3s ease-in-out;
}

[type='search']::-webkit-search-cancel-button {
  -webkit-appearance: none;
  background-color: var(--color);
  -webkit-mask-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='%23777'><path d='M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z'/></svg>");
  background-size: 20px 20px;
  height: 20px;
  width: 20px;
}

.hidden-toggle {
  position: absolute;
  opacity: 0;
  pointer-events: none;
}

.purple-icon-color-dark {
  color: #7379ff;
}

.purple-icon-color-light {
  color: #464bba;
}

@keyframes shimmer {
  0% {
    background-position: -200%;
  }
  100% {
    background-position: 200%;
  }
}

.shimmer-effect {
  background: linear-gradient(
    90deg,
    rgba(157, 157, 157, 0.165) 0%,
    rgba(255, 255, 255, 0.6) 50%,
    rgba(157, 157, 157, 0.377) 100%
  );
  background-size: 200%;
  animation: shimmer 5s infinite;
}

.shimmer-effect-dark {
  background: linear-gradient(
    90deg,
    rgba(83, 83, 83, 0.9) 0%,
    rgba(138, 138, 138, 0.6) 50%,
    rgba(83, 83, 83, 0.9) 100%
  );
  background-size: 200%;
  animation: shimmer 5s infinite;
}

@keyframes shimmer {
  0% {
    background-position: -100% 0;
  }
  100% {
    background-position: 100% 0;
  }
}
